import { UserActions, UserActionTypes } from './user.actions';
import { CallingState, CallState } from '../../state/app.state';

export interface UserState {
  users: any;
  callState: CallState;
}

const initialState: UserState = {
  users: [],
  callState: CallingState.INIT
};

export function userReducer(state = initialState, action: UserActions): UserState {

  switch (action.type) {

    case UserActionTypes.LOAD_USERS:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case UserActionTypes.LOAD_USERS_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        users: action.users
      };
    case UserActionTypes.LOAD_USERS_FAILURE:
      return {
        ...state,
        callState: {errorMsg: action.error}
      };
    case UserActionTypes.DELETE_USER || UserActionTypes.CREATE_USER:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case UserActionTypes.DELETE_USER_SUCCESS || UserActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case UserActionTypes.DELETE_USER_FAILURE || UserActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case UserActionTypes.CREATE_USER:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case UserActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case UserActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    default: {
      return state;
    }
  }
}

